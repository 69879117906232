import React, { useContext } from "react";
import styled from "styled-components";
import { VPSReportContext } from "../Container";
import moment from "moment";
import { useSelector } from "react-redux";

const StyledComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  color: ${(props) => (props.theme === "dark" ? "white" : "black")};
  .vps-logo-wrapper {
    width: 11rem;
    height: 11rem;

    img {
      border-radius: 50%;
      object-fit: fill;
    }
  }

  .vps-report-details-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: stretch;
    .vps-report-detail-wrapper {
      display: flex;
      gap: 0.3rem;
      align-items: center;
      span {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        /* color: rgba(0, 0, 0, 1); */

        color: ${(props) => (props.theme === "dark" ? "white" : "black")};
      }
      .vps-report-detail-value {
        font-family: "Poppins";
        font-size: 12px;
      }
    }
  }
`;

export const VPSReportHeading = () => {
  const { VPSReportInstance } = useContext(VPSReportContext);

  const { theme } = useSelector((state) => state.themeReducer);

  return (
    <StyledComponent theme={theme}>
      <div className='vps-logo-wrapper'>
        <img
          className='bounded-image'
          src={VPSReportInstance?.data?.vps_instance?.logo?.file}
          alt={"Logo not found"}
        />
      </div>
      <div className='vps-report-details-wrapper'>
        <div className='vps-report-detail-wrapper'>
          <span className='vps-report-detail-title'>Date:</span>

          <div className='vps-report-detail-value'>
            {VPSReportInstance?.data?.date_last_modified &&
              moment(VPSReportInstance?.data?.date_last_modified).format(
                "DD-MM-YYYY"
              )}
          </div>
        </div>
        <div className='vps-report-detail-wrapper'>
          <span className='vps-report-detail-title'>Location:</span>

          <div className='vps-report-detail-value'>
            {VPSReportInstance?.data?.vps_instance?.location}
          </div>
        </div>
        <div className='vps-report-detail-wrapper'>
          <span className='vps-report-detail-title'>Time Start:</span>
          {/* <div className='vps-report-detail-separator'>:</div> */}
          <div className='vps-report-detail-value'>
            {VPSReportInstance?.data?.date_added &&
              moment(VPSReportInstance?.data?.date_added).format("HHmm") +
                " Hrs"}
          </div>
        </div>
        <div className='vps-report-detail-wrapper'>
          <span className='vps-report-detail-title'>Time Ended:</span>
          {/* <div className='vps-report-detail-separator'>:</div> */}
          <div className='vps-report-detail-value'>
            {VPSReportInstance?.data?.date_last_modified &&
              moment(VPSReportInstance?.data?.date_last_modified).format(
                "HHmm"
              ) + " Hrs"}
          </div>
        </div>
      </div>
    </StyledComponent>
  );
};
